<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "SnkContact",
		extends: Base,
		data() {
			return {
				Name: "SnkContact",
				row:{},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods:{			
	        submitFormSlider(e, callback){
	          if(e&&e.btnLoading()) return;
	          this.row.type = 'EditHeader'
	          BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
	            if(e) e.btnUnloading()
	            if(err){
	              if(err.status == 422){
	                Object.keys(err.responseJSON).forEach((k)=>{
	                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
	                })
	              }
	              return
	            }
	            if(resp.error){
	              if(this.errorFormCallback) return this.errorFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "danger")
	            }
	            if(resp.success){
	              if(this.successFormCallback) return this.successFormCallback(resp);
	              if(callback) return callback(resp);
	              return Gen.info(resp.message, "success", 1000).then(()=>{
	                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
	              })
	            }
	          },"POST")
	        },  
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.project'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="submitFormSlider" method="post">
							<div class="row">
								<div class="info col-12"></div>
								<div class="col-12">
									<h5 class="card-title">Hero Image</h5>
								</div>
								<div class="col-md-4">
									 <BoField name="sc_background" mandatory>
                                        <Uploader :param="{thumbnail:true}" name="sc_background" v-model="row.sc_background"
                                            type="background" uploadType="cropping"></Uploader>
                                    </BoField>
								</div>
								<div class="col-md-8">
									<div class="row">
										<div class="col-6">
											<BoField name="sc_title" v-model="row.sc_title"></BoField>
										</div>
									</div>
								<BoField name="sc_sub_title" v-model="row.sc_sub_title"></BoField>
									<BoField name="sc_desc" :label="'Embed Script Maps'">
										<textarea v-model="row.sc_desc" class="form-control" rows="5"></textarea>
									</BoField>
								</div>
								<div class="col-12">
									<div class="text-right">
										<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Update Header</button>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-6">
							<BoField name="mc_full_name" v-model="row.mc_full_name"></BoField>
							<BoField name="mc_email" v-model="row.mc_email"></BoField>
							<BoField name="mc_phone" v-model="row.mc_phone"></BoField>
							<BoField name="mc_subject" v-model="row.mc_subject"></BoField>
							<BoField name="mc_message" >
								<textarea name="content" class="form-control" v-model="row.mc_message"></textarea>
							</BoField>
							<BoField name="mc_is_active">
								<div class="row">
									<radio name="mc_is_active" v-model="row.mc_is_active" option="Y" :attr="validation('mc_is_active')">Read</radio>
									<radio name="mc_is_active" v-model="row.mc_is_active" option="N">Unread</radio>
								</div>
							</BoField>
						</div>

						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Save Partner</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>